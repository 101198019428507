export const routes = [
    {
        path: '/patient/create',
        component: () => import(/* webpackChunkName: "patient" */ './Create'),
        name: 'patient.create',
        meta: { requiresAuth: true }
    },
    {
        path: '/patient/list',
        component: () => import(/* webpackChunkName: "patient" */ './List'),
        name: 'patient.list',
        meta: { requiresAuth: true }
    },
    {
        path: '/patient/get/:id',
        component: () => import(/* webpackChunkName: "patient" */ './Get'),
        name: 'patient.get',
        meta: { requiresAuth: true }
    },
    {
        path: '/patient/get/:id/questionnaire',
        component: () => import(/* webpackChunkName: "patient" */ './CreateQuestionnaire'),
        name: 'patient.get.questionnaire',
        meta: { requiresAuth: true }
    },
    {
        path: '/patient/get/:id/questionnaire/:questionnaire',
        component: () => import(/* webpackChunkName: "patient" */ './GetQuestionnaire'),
        name: 'patient.get.questionnaire.get',
        meta: { requiresAuth: true }
    },
    {
        path: '/patient/resume-creation/:id',
        component: () => import(/* webpackChunkName: "patient" */ './ResumeCreation'),
        name: 'patient.resume.creation',
        meta: { requiresAuth: true }
    },
    {
        path: '/patient/search',
        component: () => import(/* webpackChunkName: "patient" */ './Search'),
        name: 'patient.search',
        meta: { requiresAuth: true }
    }
]