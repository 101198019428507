export default (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.getItem('token') === null) {
        next({
          path: '/auth/login'
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }