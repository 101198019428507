export const routes = [
    {
        path: '/user/create',
        component: () => import(/* webpackChunkName: "user" */ './Create'),
        name: 'user.create',
        meta: { requiresAuth: true }
    },
    {
        path: '/user/list',
        component: () => import(/* webpackChunkName: "user" */ './List'),
        name: 'user.list',
        meta: { requiresAuth: true }
    },
    {
        path: '/user/get/:id',
        component: () => import(/* webpackChunkName: "user" */ './Get'),
        name: 'user.get',
        meta: { requiresAuth: true }
    },
    {
        path: '/user/view/:id',
        component: () => import(/* webpackChunkName: "user" */ './View'),
        name: 'user.view',
        meta: { requiresAuth: true }
    },
    {
        path: '/user',
        component: () => import(/* webpackChunkName: "user" */ './UserHome'),
        name: 'user.home',
        meta: { requiresAuth: true }
    },
    {
        path: '/user/update-password/:id',
        component: () => import(/* webpackChunkName: "user" */ './UpdatePassword'),
        name: 'user.update.password',
        meta: { requiresAuth: true }
    }
]