import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    loggedUser: {},
    menu: [],
    token: null,
    breadcrumbs: [],
    header: {
      description: '',
      redirectlastRoute: "/home"
    }
  },
  getters: {
    loading (state) {
      return state.loading
    },
    loggedUser (state) {
      return state.loggedUser
    },
    menu (state) {
      return state.menu
    },
    token (state) {
      return state.token
    },
    breadcrumbs (state) {
      return state.breadcrumbs
    },
    header (state) {
      return state.header
    }
  },
  mutations: {
    SET_LOADING (store, payload) {
      store.loading = payload
    },
    SET_MENU (store, payload) {
      store.menu = payload
    },
    SET_USER (store, payload) {
      store.loggedUser = payload
    },
    SET_TOKEN (store, payload) {
      store.token = payload
    },
    SET_BREADCRUMBS (store, payload) {
      store.breadcrumbs = payload
    },
    SET_HEADER (store, payload) {
      store.header = payload
    }
  },
  actions: {
    setLoading ({ commit }, payload) {
      commit('SET_LOADING', payload)
    },
    setMenu ({ commit }, payload) {
      commit('SET_MENU', payload)
      localStorage.setItem('menu', JSON.stringify(payload))
    },
    setUser ({ commit }, payload) {
      commit('SET_USER', payload)
      localStorage.setItem('user', JSON.stringify(payload))
    },
    setToken ({ commit }, payload) {
      commit('SET_TOKEN', payload)
      localStorage.setItem('token', payload)
    },
    setBreadcrumbs ({ commit }, payload) {
      commit('SET_BREADCRUMBS', payload)
    },
    setHeader ({ commit }, payload) {
      commit('SET_HEADER', payload)
    }
  },
  modules: {
  }
})
