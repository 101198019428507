<template>
  <v-app>
    <LoaderComponent :is-visible="loading"></LoaderComponent>

    <v-app-bar
      v-if="!$route.path.includes('auth')"
      color="#ccc"
    >
      <v-col cols="3" class="text-left" v-if="!$route.path.includes('home')">
        <v-btn
          rounded
          block
          color="primary"
          @click="redirectPage(header.redirectlastRoute)"
        >
          Voltar
        </v-btn>
      </v-col>

      <v-col :cols="$route.path.includes('home') ? '9' : '6'" class="logo_img_col">
        <v-img
          :class="!$route.path.includes('home') ? 'logo_img' : ''"
          alt="SBGG SP Logo"
          src="https://www.sbgg-sp.com.br/wp-content/uploads/2020/08/Logo-SBGG_SP_white.png"
          width="100"
        />
      </v-col>
      
      <v-col cols="3" class="text-right">
        <v-btn
          rounded
          block
          color="primary"
          @click="logout"
        >
          Sair
        </v-btn>
      </v-col>
    </v-app-bar>
    <v-divider></v-divider>

    <v-main>
        <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoaderComponent from '@/components/LoaderComponent'

export default {
  name: 'App',
  components: {
    LoaderComponent
  },
  computed: {
    ...mapGetters([
      'breadcrumbs',
      'loading',
      'header'
    ])
  },
  created () {
    let menu = JSON.parse(localStorage.getItem('menu')) || []
    let token = localStorage.getItem('token') || {}
    let user = JSON.parse(localStorage.getItem('user')) || {}
    
    if (user) {
      this.setUser(user);
      this.setToken(token);
      this.setMenu(menu);
    }
    else
      this.$router.push('auth/login').catch(() =>{})
  },
  data: () => ({
    drawer: false
  }),
  methods: {
    ...mapActions(['setMenu', 'setToken', 'setUser']),
    enableInterceptor () {
      this.$http.interceptors.request.use(
        config => {
          return config
        },
        error => {
          return Promise.reject(error)
        }
      )
      this.$http.interceptors.response.use((response) => {
        return response
      }, (error) => {
        return Promise.reject(error);
      })
    },
    redirectPage (action) {
      this.$router.push(action).catch(() =>{})
    },
    logout () {
      this.$router.push('/auth/login').catch(() =>{})
    }
  },
  mounted () {
    this.enableInterceptor()
  }
};
</script>

<style scoped lang="scss">
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #fff;
  font-weight: 500;
}

.v-divider {
  border: 0px;
}

.logo_img_col {
  justify-content: center;
}

.logo_img {
   margin: auto;
   display: block;
}
</style>