import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMoment from 'vue-moment'
import VueMask from 'v-mask'
import VueTheMask from 'vue-the-mask'

import Vuetify from 'vuetify'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import http from './http'

Vue.prototype.$http = http
Vue.use(VueRouter)
Vue.use(VueMoment)
Vue.use(VueMask);
Vue.use(VueTheMask)
Vue.use(Vuetify, {
   theme: {
      primary: '#7957d5',
   },
})

Vue.config.productionTip = false

new Vue({
   router,
   store,
   vuetify,
   render: h => h(App)
}).$mount('#app')
