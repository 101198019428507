import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

import AfterEach from './AfterEach.js'
import BeforeEach from './BeforeEach.js'

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes: routes
})

router.afterEach(AfterEach)
router.beforeEach(BeforeEach)

export default router
