export const routes = [
    {
        path: '/questionnaire/create',
        component: () => import(/* webpackChunkName: "questionnaire" */ './Create'),
        name: 'questionnaire.create',
        meta: { requiresAuth: true }
    },
    {
        path: '/questionnaire/get/:id',
        component: () => import(/* webpackChunkName: "questionnaire" */ './Get'),
        name: 'questionnaire.get',
        meta: { requiresAuth: true }
    },
    {
        path: '/questionnaire/list',
        component: () => import(/* webpackChunkName: "questionnaire" */ './List'),
        name: 'questionnaire.list',
        meta: { requiresAuth: true }
    },
    {
        path: '/questionnaire/answer/:id',
        component: () => import(/* webpackChunkName: "questionnaire" */ './Answer'),
        name: 'questionnaire.answer',
        meta: { requiresAuth: true }
    },
    {
        path: '/questionnaire/view/:id',
        component: () => import(/* webpackChunkName: "questionnaire" */ './View'),
        name: 'questionnaire.view',
        meta: { requiresAuth: true }
    },
    {
        path: '/questionnaire/list/answer',
        component: () => import(/* webpackChunkName: "questionnaire" */ './AnswerList'),
        name: 'questionnaire.list/answer',
        meta: { requiresAuth: true }
    }
]