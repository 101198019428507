export const routes = [
    {
        path: '/patient-questionnaire/create/:id',
        component: () => import(/* webpackChunkName: "patientQuestionnaire" */ './Create'),
        name: 'patient-questionnaire.create',
        meta: { requiresAuth: true }
    },
    {
        path: '/patient-questionnaire/list/:id/questionnaire/:questionnaire',
        component: () => import(/* webpackChunkName: "patientQuestionnaire" */ './List'),
        name: 'patient-questionnaire.list',
        meta: { requiresAuth: true }
    },
    {
        path: '/patient-questionnaire/get/:id',
        component: () => import(/* webpackChunkName: "patientQuestionnaire" */ './Get'),
        name: 'patient-questionnaire.get',
        meta: { requiresAuth: true }
    },
    {
        path: '/patient-questionnaire/answer/:id',
        component: () => import(/* webpackChunkName: "patientQuestionnaire" */ './Answer'),
        name: 'patient-questionnaire.answer',
        meta: { requiresAuth: true }
    }
]