import { routes as auth } from '@/modules/auth'
import { routes as home } from '@/modules/home'
import { routes as patient } from '@/modules/patient'
import { routes as patientQuestionnaire } from '@/modules/patient-questionnaire'
import { routes as questionnaire } from '@/modules/questionnaire/index.js'
import { routes as user } from '@/modules/user'

export default [
    {
        path: '*',
        redirect: '/auth/login'
    },
    ...auth,
    ...home,
    ...patient,
    ...patientQuestionnaire,
    ...questionnaire,
    ...user
]